import React from "react"
import { graphql} from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleList from "../components/article-list"

// To convert string into Title Case
function titleCase(str){
	str = str.toLowerCase().split(' ');
	let final = [ ];
	for(let  word of str){
		 final.push(word.charAt(0).toUpperCase()+ word.slice(1));
	}
  return final.join(' ')
}

// 
function ArticleListByCategory( props ) {
    // retrieve context variable `uniqueCategory`
    const { uniqueCategory } = props.pageContext
    // convert category into Title Case format
	const uniqueCategoryTitleCase = titleCase(uniqueCategory)
	return (
		<Layout>
			<SEO title={uniqueCategoryTitleCase} keywords={[uniqueCategory, `article`, `tutorial`]} />
			<div>
				<ArticleList data={ props.data }/>
			</div>
		</Layout>
	)
}
export default ArticleListByCategory

export const query = graphql`
	query ArticleListByCategoryQuery($uniqueCategory: String!) {
		allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___publish },
            filter: { frontmatter: { category: { eq: $uniqueCategory } } }
        ){
			totalCount
			edges {
				node {
					id
					frontmatter {
						title
                        publish(formatString: "MMM DD, YYYY")
                        update(formatString: "MMM DD, YYYY")
						path
                        category
                        keywords
					}
				}
			}
		}
	}
`

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony disqus frontmatter  